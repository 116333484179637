.cardMainlogin {
    /* width: 55rem; */
    margin: auto;
    background-color: white;
    justify-content: center;
    align-items: center;
    /* border: 1px solid white; */
    border: none;
    border-radius: 8px;
    margin-top: 15% !important;
    margin-left: 15%;
    margin-right: 15%;
  }
  .cardMainlogin .card{
    height: 380px;
  }
  .loginheader {
    /* text-align: center !important; */
    font-size: 24px;
    color: #151b39;
    /* margin-top: 10%; */
    font-weight: 600 !important;
    text-align: center;
  }
  
  .loginform label{
    font-size: 15px;
    color: black;
    /* font-weight: 300 !important; */
    /* text-transform: uppercase; */
  }
  .loginform {
    margin-top: 20px;
    /* margin-top: 5%;
    width: 350px;
    margin-left: auto;
    margin-right: auto; */
  }
  .loginundelne {
    border-bottom: 2px solid #023E8A;
    margin-top: 5%;
    width: 480px;
    margin: auto;
  }
  .contactpagebtn {
    width: 100%;
    margin-top: 20px;
    padding-top: 7px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 10px;
  
    font-family: "Montserrat", sans-serif;
    text-align: center;
    color: white;
  
    background-color: #023E8A;
    box-shadow: 1px solid #023E8A;
    border: 1px solid #023E8A !important;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  .contactpagebtn:hover {
    background-color: #023E8A;
    box-shadow: 1px solid #023E8A;
    border: 1px solid #023E8A !important;
    color: white;
    border: 1px solid #023E8A;
  }
  .contactpagebtn:active {
    background-color: #023E8A !important;
    box-shadow: 1px solid #023E8A !important;
    border: 1px solid #023E8A !important;
  }
  .contactpagebtn:focus {
    border-color: #023E8A !important;
    background-color: #023E8A !important;
  
    outline: 0;
    box-shadow: 0 0 0 0 #023E8A !important;
  }
  
  
  .loginform .inputClass:focus {
    color: #5c6873;
    background-color: #fff;
    border-color: #e4e7ea !important;
    outline: 0;
    box-shadow: 0 0 0 0 #e4e7ea !important;
  }
  
  .loginform .inputClass {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #e4e7ea;
    border-radius: 0rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .loginform form {
    margin-bottom: 1px;
  }
  .loginformnames {
    font-size: 12px;
  }
  
  .buttonRow{
      display: flex;
      justify-content: center;
  }
  
  .cardHeading{
    background-color: #023E8A;
    color: white;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  .imgDiv{
    text-align: center;
    margin-top: 30px;
  }
  .loginfrstImg{
    width: 200px;
  }
  .formDiv{
    max-width: 300px;
    margin: auto;
  }
  .errStyle{
    color: red;
    font-size: 12px;
    margin-bottom: 0px !important;
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .cardMainlogin {
      width: 40rem;
      margin-top: 12% !important;
      margin-left: 0%;
      margin-right: 0%;
    }
  }
  @media (max-width: 767px) and (min-width: 550px) {
    .cardMainlogin {
      width: 30rem;
  
      margin-top: 15% !important;
      margin-left: 0%;
      margin-right: 0%;
    }
  
    .loginheaderfrst {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
  @media (max-width: 549px) and (min-width: 300px) {
    .loginheaderfrst {
      margin-left: 10%;
      margin-right: 10%;
    }
   
    .cardMainlogin {
      /* width: 17rem; */
  
      margin-top: 15% !important;
      margin-left: 0%;
      margin-right: 0%;
    }
  }
  